<template>
  <v-container class="my-auto">
    <v-row v-if="!responseAlert">
      <v-col cols="12" md="6" class="mx-auto d-flex flex-column align-center">
        <h4 class="display-1 text-center dark--text">New password</h4>
        <p class="body-1 text-center mt-2">
          Think of a password to access the system
        </p>
        <v-form
          ref="form"
          v-model="valid"
          class="mt-10 dark--text forgot-password"
          lazy-validation
        >
          <v-layout align-center class="mb-2 mt-0">
            <p class="body-1 mb-0">Verification Code</p>
          </v-layout>
          <v-otp-input v-model="confirmCode" length="6" @keypress="onlyNumbers"/>
          <v-layout align-center class="mb-2 mt-0">
            <p class="body-1 mb-0">Password</p>
          </v-layout>
          <v-text-field
            background-color="#fafafa"
            v-model="password"
            autocomplete="new-password"
            outlined
            solo
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passwordRules"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            flat
            hint="At least 8 characters"
            @click:append="show1 = !show1"
          ></v-text-field>

          <v-btn
            :disabled="!valid || !password.length || confirmCode.length < 6"
            color="primary"
            class="py-6 text-capitalize"
            block
            @click="passwordRecent"
          >
            {{ $vuetify.breakpoint.smAndUp ? 'Reset Password' : 'Confirm' }}
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <v-row v-else class="d-flex justify-center">
      <div class="numberSection text-center px-3">
        <v-img
          :src="require('../assets/' + responseAlertImage)"
          contain
          width="40"
          height="40"
          max-width="min-content"
          class="mb-6 mx-auto"
        />
        <h4 class="dark--text verifySuccess">{{ responseAlertMessage }}</h4>
        <v-btn
          @click="confirmAlert"
          color="primary"
          class="mt-12 text-none"
          :class="{ 'w-100' :$vuetify.breakpoint.smAndDown }"
          depressed
        >
          Continue
        </v-btn>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ResetPassword',
  data: () => ({
    valid: true,
    responseAlert: false,
    responseAlertMessage: '',
    responseAlertImage: '',
    password: '',
    passwordRules: [
      (v) => !!v || 'Password is required',
      (v) =>
        (v &&
          v.length >= 8 &&
          v.length <= 32 &&
          /[A-Z]/.test(v) &&
          /\d/.test(v) &&
          /[#?!@$%^&*-]/.test(v)) ||
        'Enter 8 to 32 characters, including 1 number, 1 lowercase letter, 1 uppercase letter and 1 special character'
    ],
    show1: false,
    show2: true,
    token: '',
    confirmCode: '',
    hasError: false
  }),

  mounted () {
    if (this.$route.params.token) this.token = this.$route.params.token
  },

  methods: {
    ...mapActions(['changePassword']),
    validate() {
      this.$refs.form.validate()
    },
    async passwordRecent() {
      try {
        if (!this.token) {
          this.responseAlertMessage = 'Token is required'
          this.responseAlertImage = 'png/error.png'
        }
        await this.changePassword({
          code: parseInt(this.confirmCode),
          token: this.token,
          newPassword: this.password
        })
        this.responseAlertMessage = 'Password is successfully modified'
        this.responseAlertImage = 'svg/verify-success.svg'
      } catch (err) {
        this.responseAlertMessage = err.message
        this.responseAlertImage = 'png/error.png'
        this.hasError = true
      } finally {
        this.responseAlert = true
      }
    },
    confirmAlert() {
      if (!this.hasError) return this.$router.push('/login')
      this.responseAlertMessage = ''
      this.responseAlertImage = ''
      this.confirmCode = ''
      this.password = ''
      this.responseAlert = false
      this.hasError = false
    },
    onlyNumbers (event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
    }
  }
}
</script>

<style scoped lang="scss">
.forgot-password {
  width: min(560px, 90vw);
}
.v-text-field::v-deep {
  .v-input__slot {
    height: 60px;
  }
  .v-input__append-inner .v-icon {
    color: #0000008a !important;
  }
  &:not(.error--text) {
    .v-input__slot fieldset {
      border: 1px solid #e3e3e3 !important;
    }
    &.v-input--is-dirty {
      .v-input__slot fieldset {
        border: 1px solid #27ae60 !important;
      }
      &.v-input--is-focused {
        .v-input__slot fieldset {
          border: 1px solid #5758b0 !important;
        }
      }
    }
  }
}
.v-btn--outlined.light--text {
  border: 1px solid #d6d8e3;
  width: 100%;
}
</style>
